import React from "react";
import Hero from "../Hero";
import { graphql, StaticQuery } from "gatsby";

const Intro = () => (
  <StaticQuery
    query={graphql`
      {
        file(relativePath: { glob: "**/intro_background.jpg" }) {
          absolutePath
          childImageSharp {
            gatsbyImageData(
              outputPixelDensities: [0.0625, 0.125, 0.25, 0.5, 1]
              placeholder: DOMINANT_COLOR
            )
          }
        }
      }
    `}
    render={(data) => (
      <Hero
        image={data.file.childImageSharp.gatsbyImageData}
        title="Wettbewerb für MINT & Robotik"
        // subtitle="International Robotics Competition"
        emphasizeTitle="Season Two"
        desc={
          <>
            <p>
              compAIR ist ein innovativer MINT / Robotik-Wettbewerb für
              Jugendliche, der sich mit autonomen Fahrzeugen und der "Augmented
              Reality" beschäftigt.
            </p>
            <p>
              <b>Season 4.0: 29. + 30. November 2024 im Airlab der HTL Wr. Neustadt</b>
            </p>
          </>
        }
      // cta="Jetzt Registrieren!" // uncomment to open registration for next saeson
      />
    )}
  />
);

export default Intro;
