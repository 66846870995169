import React from "react";
import Section from "../Section";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql, StaticQuery } from "gatsby";

function compareSponsors(a, b) {
  if (a.fileAbsolutePath > b.fileAbsolutePath) {
    return 1;
  } else if (a.fileAbsolutePath < b.fileAbsolutePath) {
    return -1;
  } else {
    return 0;
  }
}

const Sponsors = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          sponsors_svg_md: allMarkdownRemark(
            filter: {
              fileAbsolutePath: { regex: "/sponsors/" }
              frontmatter: { pic: { extension: { eq: "svg" } } }
            }
            sort: { order: ASC, fields: [fileAbsolutePath] }
          ) {
            nodes {
              fileAbsolutePath
              frontmatter {
                link
                pic {
                  extension
                  publicURL
                }
              }
            }
          }

          sponsors_img_md: allMarkdownRemark(
            filter: {
              fileAbsolutePath: { regex: "/sponsors/" }
              frontmatter: { pic: { extension: { ne: "svg" } } }
            }
            sort: { order: ASC, fields: [fileAbsolutePath] }
          ) {
            nodes {
              fileAbsolutePath
              frontmatter {
                link
                pic {
                  extension
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      width: 125
                      placeholder: TRACED_SVG
                    )
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        const sponsors = data.sponsors_img_md.nodes
          .concat(data.sponsors_svg_md.nodes)
          .map((sponsor) => {
            return {
              ...sponsor,
              fileAbsolutePath: sponsor.fileAbsolutePath.toLowerCase(),
            };
          })
          .sort(compareSponsors);

        return (
          <Section>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8 md:gap-32">
              {sponsors.map((sponsor) => {
                return (
                  <a
                    className="object-contain flex items-center justify-center"
                    key={sponsor.fileAbsolutePath}
                    rel="nofollow noreferrer"
                    target="_blank"
                    href={sponsor.frontmatter.link}
                  >
                    {sponsor.frontmatter.pic.extension === "svg" ? (
                      <img
                        className="flex-shrink-0"
                        alt={sponsor.frontmatter.link}
                        src={sponsor.frontmatter.pic.publicURL}
                      />
                    ) : (
                      <GatsbyImage
                        alt={sponsor.frontmatter.link}
                        image={
                          sponsor.frontmatter.pic.childImageSharp
                            .gatsbyImageData
                        }
                      />
                    )}
                  </a>
                );
              })}
            </div>
          </Section>
        );
      }}
    />
  );
};

export default Sponsors;
